import "../Styles/index.scss";

import Flickity from "flickity-fade";
import Bowser from "bowser";
import React from "dom-chef";
import debounce from "lodash/debounce";
import getSize from "get-size";
import imagesLoaded from "imagesloaded";
import mediumZoom from "@wagich/medium-zoom";

import { emToPx, delay } from "./utils";

const navigationContainer = document.getElementById("navigationContainer")!;
const contentContainer = document.getElementById("contentContainer")!;
const scrollWrapper = document.getElementById("contentScrollWrapper")!;
const textContainer = document.getElementById("textContainer")!;
const columnContainer = document.getElementById("columnContainer")!;
const backgroundVideo = document.getElementById("backgroundVideo") as HTMLVideoElement | null;
const multicolumnMq = window.matchMedia("(min-width: 75em)");

async function toggleContent() {
	// disable open-once listener
	contentContainer.removeEventListener("mouseover", toggleContent);

	if (contentContainer.classList.contains("closed")) {
		setContentContainerPosition();
		window.setTimeout(() => {
			// set 500ms delay to return from overflow:hidden because of the css transition
			scrollWrapper.style.overflowX = "";
		}, 500);
	} else {
		scrollWrapper.style.overflowX = "hidden";
		contentContainer.style.left = `${window.innerWidth - emToPx("16em")}px`;
	}

	contentContainer.classList.toggle("closed");
	document.getElementById("secondaryNavigationContainer")?.classList.toggle("contentClosed");
	document.querySelector(".sequence-controls")?.classList.toggle("contentClosed");

	await delay(250);
	const toggleSymbol = document.querySelector("#hideContentButton .symbol");
	if (toggleSymbol != null) {
		toggleSymbol.textContent = contentContainer.classList.contains("closed") ? "«" : "»";
	}
}

function setContentContainerPosition() {
	let navigationWidth = getSize(navigationContainer).outerWidth;

	let columnContainerWidth = columnEndMarker.offsetLeft + getSize(columnEndMarker).width + emToPx(2);
	columnContainer.style.width = `${columnContainerWidth}px`;

	let contentSpace = window.innerWidth - navigationWidth - emToPx(6);

	if (columnContainerWidth < contentSpace) {
		contentContainer.style.left = `${Math.floor(navigationWidth + (contentSpace - columnContainerWidth))}px`;
	} else {
		contentContainer.style.left = "";
	}
}

function initMulticolumn() {
	textContainer?.appendChild(<div id="hideContentButton" onClick={toggleContent}><span className="symbol">»</span></div>);

	setContentContainerPosition();
	if (document.body.getAttribute("data-nocollapse") == null) {
		contentContainer.addEventListener("mouseover", toggleContent, { once: true });
		toggleContent();
	}

	window.addEventListener("resize", multicolumnResizeDebounced);
}
function destroyMulticolumn() {
	multicolumnResizeDebounced.cancel();
	window.removeEventListener("resize", multicolumnResizeDebounced);
	textContainer.querySelector("#hideContentButton")?.remove();
	contentContainer.style.left = "";
	columnContainer.style.width = "";
}

const multicolumnResizeDebounced = debounce(multicolumnResize, 250);
async function multicolumnResize() {
	if (!contentContainer.classList.contains("closed")) {
		contentContainer.style.left = "";
		setContentContainerPosition();

		// set 500ms delay to return from overflow:hidden because of the css transition
		await delay(500);
		scrollWrapper.style.overflowX = "";
	} else {
		contentContainer.style.left = `${window.innerWidth - emToPx("10em")}px`;
	}
}

const columnEndMarker = <span id="columnEndMarker" />;
columnContainer.appendChild(columnEndMarker);

// only initialize multicolumn if we're on a decent size screen
if (multicolumnMq.matches) {
	imagesLoaded(columnContainer, initMulticolumn);
}
multicolumnMq.addEventListener("change", mq => {
	if (mq.matches) {
		initMulticolumn();
	} else {
		destroyMulticolumn();
	}
});

// HIDE NAVIGATION OVERLAY
async function toggleNavigation() {
	navigationContainer?.classList.toggle("closed");
	contentContainer?.classList.toggle("expanded");

	delay(250);
	let toggleNavigationSymbol = document.querySelector("#hideNavigationButton .symbol");
	if (toggleNavigationSymbol != null) {
		toggleNavigationSymbol.textContent = navigationContainer?.classList.contains("closed") ? "»" : "«";
	}
}
const toggleNavigationButton = <div id="hideNavigationButton" onClick={toggleNavigation}><span className="symbol">«</span></div>;
navigationContainer?.appendChild(toggleNavigationButton);

var flickity = new Flickity("#sequence", {
	fade: true,
	pageDots: false,
	prevNextButtons: false,
	autoPlay: 4000,
	setGallerySize: false,
	pauseAutoPlayOnHover: false,
});

document.querySelector(".sequence-prev")?.addEventListener("click", () => flickity.previous());
document.querySelector(".sequence-next")?.addEventListener("click", () => flickity.next());

if (backgroundVideo != null) {
	const browser = Bowser.getParser(window.navigator.userAgent);
	if (browser.getPlatformType(true) !== "mobile" && multicolumnMq.matches) {
		flickity.pausePlayer();
		backgroundVideo.play();
	}

	flickity.on("change", (index?: number) => {
		if (index === 0) {
			flickity.pausePlayer();
			backgroundVideo.play();
		}
	});
	backgroundVideo.addEventListener("ended", e => {
		flickity.next();
		flickity.playPlayer();
	});
}

// add filler strip between navigation and content and scroll it out with the content,
// hiding the gap between the fixed faux-background of the columns and the real left edge
var filler = <div id="contentScrollerBackgroundFiller" />;
contentContainer.appendChild(filler);
scrollWrapper?.addEventListener("scroll", e => {
	var newLeft = emToPx("4em") - scrollWrapper.scrollLeft;
	filler.style.left = `${Math.max(newLeft, 0)}px`;
});

mediumZoom(".is-zoomable", {
	margin: 50,
	background: "rgba(255,255,255,0.8)"
});
